import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Link } from "react-router-dom";
import { models } from "@/types/interfaces";

interface RecentTradesProps {
	trades: models.Trade[];
	// You can add more props here if needed
}

export function RecentTrades({ trades }: RecentTradesProps) {
	return (
		<Tabs defaultValue="recent" className="w-full">
			<TabsList className="w-full grid-cols-3 gap-2 items-center">
				<TabsTrigger value="recent">Recent Trades</TabsTrigger>
				<TabsTrigger value="open">Active Trades</TabsTrigger>
			</TabsList>
			<div className="m-2 mt-6">
				<TabsContent value="recent">
					<div className="space-y-3">
						{trades.map((trade) => (
							<Link to={`/trades/${trade.uuid}`} key={trade.id}>
								<div
									className="flex items-center cursor-pointer hover:bg-muted-foreground/10 rounded-sm mb-2"
									key={trade.id}
								>
									<Avatar className="h-8 w-8 md:h-9 md:w-9 text-xs md:text-sm">
										<AvatarImage src="" alt="Avatar" />
										<AvatarFallback>{trade.symbol.slice(0, 3)}</AvatarFallback>
									</Avatar>
									<div className="ml-4 ">
										<span className="text-xs md:text-sm font-medium leading-none">
											{trade.symbol}
										</span>
										<span className="text-xs md:text-sm ml-2 text-muted-foreground">
											<Badge
												variant={
													trade.trade_type === "buy"
														? "constructive"
														: "destructive"
												}
											>
												{trade?.trade_type === "buy" ? "BUY" : "SELL"}{" "}
												{trade?.volume}
											</Badge>
										</span>
										<p className="text-xs md:text-sm text-muted-foreground">
											{trade.avg_buy_price} &rarr; {trade.avg_sell_price}
										</p>
									</div>
									<div
										className={`ml-auto mr-2 font-medium text-right ${
											trade.profit > 0 ? "text-emerald-500" : "text-rose-500"
										}`}
									>
										<p>
											{trade.profit > 0
												? `+${trade.profit}`
												: `${trade.profit}`}
										</p>
										<p className="text-muted-foreground text-xs md:text-sm font-normal">
											{new Date(trade.open_date).toLocaleString("en-US", {
												month: "short",
												day: "numeric",
												year: "numeric",
											})}
										</p>
									</div>
								</div>
							</Link>
						))}
					</div>
				</TabsContent>
				<TabsContent value="open">
					<div />
				</TabsContent>
				<TabsContent value="closed">
					<div />
				</TabsContent>
			</div>
		</Tabs>
	);
}
