import { models } from "@/types/interfaces";
import { apiFetch } from "./utils";

let API_BASE_URL: string | undefined;
API_BASE_URL = import.meta.env.VITE_BASEURL;
API_BASE_URL = API_BASE_URL ? `${API_BASE_URL}/api/trades` : undefined;

export async function GetAllTrades(): Promise<models.Trade[]> {
	return apiFetch<models.Trade[]>(`${API_BASE_URL}`);
}

export async function GetTradeByUUID(uuid: string): Promise<models.Trade> {
	return apiFetch<models.Trade>(`${API_BASE_URL}/${uuid}`);
}

export async function CreateTrade(
	tradeData: Omit<models.Trade, "id">
): Promise<models.Trade> {
	return apiFetch<models.Trade>(`${API_BASE_URL}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(tradeData),
	});
}

export async function GetRunningPnl(
	uuid: string
): Promise<models.RunningPnl[]> {
	return apiFetch<models.RunningPnl[]>(`${API_BASE_URL}/${uuid}/running-pnl`);
}
