import { Button } from "@/components/custom/button";
import { DatePickerWithRange } from "@/components/ui/date-range";
import { Input } from "@/components/ui/input";
import { Cross2Icon } from "@radix-ui/react-icons";
import { IconTrash } from "@tabler/icons-react";
import { Table } from "@tanstack/react-table";
import React from "react";
import { DateRange } from "react-day-picker";
import { DataTableViewOptions } from "../components/data-table-view-options";
interface DataTableToolbarProps<TData> {
	table: Table<TData>;
	selectedRows: string[];
	handleDelete: () => void;
}

export function DataTableToolbar<TData>({
	table,
	selectedRows,
	handleDelete,
}: DataTableToolbarProps<TData>) {
	const handleSearch = (value: string) => {
		table.setGlobalFilter(value);
	};
	const isFiltered = table.getState().columnFilters.length > 0;
	const [date, setDate] = React.useState<DateRange | undefined>(undefined);

	const handleDateRangeFilter = (newDate: DateRange | undefined) => {
		setDate(newDate);
		if (newDate?.from && newDate?.to) {
			const fromDate = new Date(newDate.from);
			const toDate = new Date(newDate.to);

			// Set the time of toDate to the end of the day
			toDate.setHours(23, 59, 59, 999);

			table.getColumn("open_date")?.setFilterValue([fromDate, toDate]);
		} else {
			table.getColumn("open_date")?.setFilterValue(undefined);
		}
	};

	return (
		<div className="m-2 flex flex-col sm:flex-row items-center justify-between gap-2">
			<div className="flex flex-1 flex-col sm:flex-row items-start sm:items-center gap-2">
				<Input
					placeholder="Search trades..."
					value={(table.getState().globalFilter as string) ?? ""}
					onChange={(event) => handleSearch(event.target.value)}
					className="h-8 w-full sm:w-[250px]"
				/>
				<DatePickerWithRange
					date={date}
					setDate={handleDateRangeFilter}
					className="w-full sm:w-auto"
				/>
				{selectedRows.length > 0 && (
					<Button
						size={"icon"}
						onClick={() => {
							handleDelete();
						}}
						variant={"outline"}
					>
						<IconTrash className="h-4 w-4 text-red-700" />
					</Button>
				)}
				{isFiltered && (
					<Button
						// variant="ghost"
						size={"icon"}
						onClick={() => {
							setDate(undefined);
							table.resetColumnFilters();
						}}
						className="h-8"
					>
						<Cross2Icon className="h-4 w-4" strokeWidth={3} />
					</Button>
				)}
			</div>
			<DataTableViewOptions table={table} />
		</div>
	);
}
