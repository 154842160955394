import { useTheme } from "@/components/theme-provider";
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "@/components/ui/chart";
import React from "react";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";

interface OverviewLine {
	chartData: any[];
	initialBalance: number;
	percentage: boolean;
}

const chartConfig = {
	value: {
		label: "Gain",
		color: "hsl(var(--chart-1))",
	},
	value2: {
		label: "Gain",
		color: "hsl(var(--chart-1))",
	},
} satisfies ChartConfig;

const OverviewLine: React.FC<OverviewLine> = ({ chartData, percentage }) => {
	const { theme } = useTheme();

	if (!chartData) return null;
	if (chartData.length === 0) return null;

	const gradientOffset = () => {
		const dataMax = Math.max(...chartData.map((i) => i.value));
		const dataMin = Math.min(...chartData.map((i) => i.value));

		if (dataMax <= 0) {
			return 0;
		}
		if (dataMin >= 0) {
			return 1;
		}

		return dataMax / (dataMax - dataMin);
	};

	const off = gradientOffset();

	return (
		<>
			<ChartContainer config={chartConfig} className="max-h-[200px] w-full">
				<AreaChart
					accessibilityLayer
					data={chartData}
					margin={{
						left: 5,
						right: 5,
					}}
				>
					<defs>
						<linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
							<stop offset="0%" stopColor="hsl(var(--chart-green))" />
							<stop offset={off} stopColor="rgba(0, 255, 0, 0.0)" />
							<stop offset={off} stopColor="rgba(255, 0, 0, 0.0)" />
							<stop offset="100%" stopColor="hsl(var(--chart-red))" />
						</linearGradient>
					</defs>

					<CartesianGrid vertical={false} horizontal={true} />
					<XAxis
						dataKey={"name"}
						tickLine={false}
						axisLine={false}
						tickMargin={8}
						// tickFormatter={(value) => Number(value).toFixed(2)}
						// tickFormatter={(value) => value.toLocaleString()}
						// tickFormatter={(value) => value.slice(0, 3)}
					/>
					<ChartTooltip
						cursor={false}
						content={<ChartTooltipContent />}
						// formatter={(value) => `Balance: $${Number(value).toFixed(2)}`}
					/>
					{/* <YAxis
							domain={[min - min * 0.1, max + max * 0.1]}
							// padding={{ top: 50, bottom: -5000 }}
							// domain={[90000, 100000]}
							// tickFormatter={(value) => value.toLocaleString()}
						/> */}
					<YAxis
						// domain={[minValue - padding, maxValue + padding]}
						tickFormatter={(value) =>
							`${Number(value).toFixed(2)}${percentage ? "%" : "$"}`
						}
						// allowDataOverflow={true}
					/>
					<Area
						dataKey={percentage ? "value2" : "value"}
						type="monotone"
						fill="url(#splitColor)"
						fillOpacity={0.4}
						stroke={theme === "dark" ? "white" : "black"}
						stackId="a"
						baseLine={95000}
						strokeWidth={1}
						strokeOpacity={0.5}
						unit="USD"
						accumulate="sum"

						// baseValue={500000}
						// domain={[min, max]}
					/>
				</AreaChart>
			</ChartContainer>
		</>
	);
};

export default OverviewLine;
