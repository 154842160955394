import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import useBackTestStore from "@/redux/slice/backTestSlice";
import {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
	VisibilityState,
	flexRender,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	FilterFn,
} from "@tanstack/react-table";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { models } from "@/types/interfaces";
import { DataTablePagination } from "../components/data-table-pagination";
import { DataTableToolbar } from "../components/data-table-toolbar";
import { rankItem } from "@tanstack/match-sorter-utils";

interface DataTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[];
	data: TData[];
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
	const itemRank = rankItem(row.getValue(columnId), value);
	addMeta({ itemRank });
	return itemRank.passed;
};

export function DataTable<TData extends models.Trade, TValue>({
	columns,
	data,
}: DataTableProps<TData, TValue>) {
	const { deleteMultipleBackTests } = useBackTestStore();
	const [rowSelection, setRowSelection] = React.useState<{
		[key: string]: any;
	}>({});
	const [columnVisibility, setColumnVisibility] =
		React.useState<VisibilityState>({});
	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
		[]
	);
	const [sorting, setSorting] = React.useState<SortingState>([]);
	// const [isOpen, setisOpen] = React.useState(false);
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			columnVisibility,
			rowSelection,
			columnFilters,
		},
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onColumnVisibilityChange: setColumnVisibility,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		globalFilterFn: fuzzyFilter,
	});

	const selectedRowUUIDs: string[] = Object.keys(rowSelection)
		.filter((index) => rowSelection[index])
		.map((index) => table.getRowModel().rows[Number(index)].getValue("uuid"));

	const navigate = useNavigate();
	const handleDelete = async () => {
		//  const delete = await dispatch(deleteMultipleBackTest({ uuid: selectedRowUUIDs }));
		try {
			await deleteMultipleBackTests(selectedRowUUIDs);
			// const result = unwrapResult(deleteBT);
			toast.success("Backtests Deleted");
			setRowSelection({});
		} catch (err) {
			console.error("An error occurred:", err);
			toast.error("An error occurred:");
		}
	};

	return (
		<div className="space-y-4">
			<DataTableToolbar
				table={table}
				selectedRows={selectedRowUUIDs}
				handleDelete={handleDelete}
			/>
			<div className="overflow-auto rounded-md border">
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableHead key={header.id} colSpan={header.colSpan}>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext()
												  )}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow
									key={row.id}
									data-state={row.getIsSelected() && "selected"}
									className={`cursor-pointer overflow-auto ${
										row.original.profit > 0
											? "bg-emerald-700/5"
											: "bg-rose-700/5"
									}`}
									onClick={() => {
										const uuid = row.original.uuid as string;
										navigate(`/trades/${uuid}`);
									}}
								>
									{row.getVisibleCells().map((cell) => (
										<TableCell key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell
									colSpan={columns.length}
									className="h-24 text-center"
								>
									No results. Please add a trade or import to start logging
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<DataTablePagination table={table} />
			{/* <NewBacktest isOpen={isOpen} setisOpen={setisOpen} /> */}
		</div>
	);
}
