import { pieChartData } from "@/interfaces/chart";
import React from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { Card, CardHeader, CardTitle } from "../ui/card";
import { test } from "node_modules/@types/ramda";
import { models } from "@/types/interfaces";
import {
	ChartConfig,
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "../ui/chart";

interface PieChartProps {
	angles: number[];
	data: models.PieChartData[];
	radii: number[];
}

const CustomTooltip = ({
	active,
	payload,
}: {
	active: boolean;
	payload: any[];
}) => {
	if (active && payload && payload.length) {
		return (
			<div className="custom-tooltip p-2 rounded-md min-w-32 bg-background/60 backdrop-blur-sm">
				<p className="text-xs font-bold text-center ">
					{payload[0].payload.name}
				</p>

				<div>
					<span className={`text-xs font-bold `}>
						{payload[0].payload.value}
					</span>
				</div>
			</div>
		);
	}

	return null;
};

const PiChart: React.FC<PieChartProps> = ({ data, angles, radii }) => {
	const chartConfig = {
		name: {
			label: "Weekday",
			color: "hsl(var(--chart-1))",
		},
		value: {
			label: "Total Trades",
			color: "hsl(var(--chart-2))",
		},
		value2: {
			label: "Total PNL",
			color: "hsl(var(--chart-3))",
		},
		value3: {
			label: "Winning Trades",
			color: "hsl(var(--chart-green))",
		},
		value4: {
			label: "Win Amount",
			color: "hsl(var(--chart-green))",
		},
		value5: {
			label: "Losing Trades",
			color: "hsl(var(--chart-red))",
		},
		value6: {
			label: "Loss Amount",
			color: "hsl(var(--chart-red))",
		},
	} satisfies ChartConfig;
	// Your component logic here

	return (
		<ChartContainer
			config={chartConfig}
			className="w-full h-full max-h-[100px] md:max-h-[100px]"
		>
			<PieChart width={radii[1] * 2} height={radii[1] + 10}>
				<Pie
					data={data}
					cx={"50%"}
					cy={"100%"}
					startAngle={angles[0]}
					endAngle={angles[1]}
					innerRadius={radii[0]}
					outerRadius={radii[1]}
					stroke="none"
					paddingAngle={0}
					dataKey="value"
					animationDuration={250}
					cornerRadius={3}
					isAnimationActive={true}
				>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={`hsl(${entry.color})`} />
					))}
				</Pie>
				<ChartTooltip
					content={
						<ChartTooltipContent
							hideLabel
							className="w-40 bg-background/45 backdrop-blur-md"
						/>
					}
				/>
			</PieChart>
		</ChartContainer>
	);
};

export default PiChart;
