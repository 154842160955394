import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";
import {
	createClient,
	SupabaseClient,
	User,
	Session,
} from "@supabase/supabase-js";
import { useNavigate, useLocation } from "react-router-dom";

// Initialize Supabase client
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export interface AuthContextType {
	user: User | null;
	session: Session | null;
	login: (email: string, password: string) => Promise<any>;
	signup: (email: string, password: string) => Promise<any>;
	logout: () => Promise<void>;
	googleLogin: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [user, setUser] = useState<User | null>(null);
	const [session, setSession] = useState<Session | null>(null);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		// Set initial session and user
		supabase.auth.getSession().then(({ data: { session } }) => {
			setSession(session);
			setUser(session?.user ?? null);

			// Only redirect if we're on the sign-in page
			if (session?.user && location.pathname === "/sign-in") {
				navigate("/");
			}
		});

		// Listen for auth changes
		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((_event, session) => {
			setSession(session);
			setUser(session?.user ?? null);

			// Only redirect if we're on the sign-in page
			if (session?.user && location.pathname === "/sign-in") {
				navigate("/");
			}
		});

		return () => subscription.unsubscribe();
	}, [navigate, location]);

	const login = async (email: string, password: string) => {
		const { data, error } = await supabase.auth.signInWithPassword({
			email,
			password,
		});
		if (error) throw error;
		return data;
	};

	const signup = async (email: string, password: string) => {
		const { data, error } = await supabase.auth.signUp({
			email,
			password,
			options: {
				emailRedirectTo: `${window.location.origin}`,
			},
		});
		if (error) throw error;
		return data;
	};

	const logout = async () => {
		const { error } = await supabase.auth.signOut();
		if (error) throw error;
	};

	const googleLogin = async () => {
		const { error } = await supabase.auth.signInWithOAuth({
			provider: "google",
			options: {
				redirectTo: `${window.location.origin}`,
			},
		});
		if (error) throw error;
	};

	return (
		<AuthContext.Provider
			value={{ user, session, login, signup, logout, googleLogin }}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = (): AuthContextType => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
