import { create } from "zustand";
import { GetDashboardData } from "@/api-methods/dashboard-controller";
import { controller } from "@/types/interfaces";
import { AuthContextType } from "@/contexts/AuthContext";

interface DashboardState {
	dashboardData: controller.DashboardData;
	error: string | null;
	loading: boolean;
	success: boolean;
	isFetching: boolean;
	fetchDashboardData: () => Promise<void>;
}

// Create an authenticated fetch wrapper

const useDashboardStore = create<DashboardState>((set, get) => ({
	dashboardData: {} as controller.DashboardData,
	error: null,
	loading: false,
	success: false,
	isFetching: false,

	fetchDashboardData: async () => {
		const { isFetching } = get();
		if (isFetching) return;
		set({ loading: true, isFetching: true });

		try {
			const response = await GetDashboardData();
			console.log("dashboard response", response);
			set({
				dashboardData: response,
				loading: false,
				error: undefined,
				success: true,
			});
		} catch (error) {
			set({ error: (error as Error).message, loading: false, success: false });
		}
	},
}));

export default useDashboardStore;
