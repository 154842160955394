import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const ProtectedRoute: React.FC = () => {
	const { user } = useAuth();
	// console.log(user, "user");

	if (!user) {
		// Redirect to the login page if the user is not authenticated
		return <Navigate to="/sign-in" replace />;
	}

	// Render the child routes if the user is authenticated
	return <Outlet />;
};

export default ProtectedRoute;
