import React from "react";
import { createBrowserRouter } from "react-router-dom";
import ErrorBoundary from "./components/error-boundary";
import GeneralError from "./pages/errors/general-error";
import MaintenanceError from "./pages/errors/maintenance-error";
import NotFoundError from "./pages/errors/not-found-error";
import Settings from "./pages/settings";
import Trades from "./pages/trades";
import Dashboard from "./pages/dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./contexts/AuthContext";
import { Outlet } from "react-router-dom";

const router = createBrowserRouter([
	{
		element: (
			<AuthProvider>
				<Outlet />
			</AuthProvider>
		),
		children: [
			// Auth routes
			{
				path: "/sign-in",
				lazy: async () => ({
					Component: (await import("./pages/auth/sign-in")).default,
				}),
			},
			{
				path: "/sign-in-2",
				lazy: async () => ({
					Component: (await import("./pages/auth/sign-in-2")).default,
				}),
			},
			{
				path: "/sign-up",
				lazy: async () => ({
					Component: (await import("./pages/auth/sign-up")).default,
				}),
			},
			{
				path: "/forgot-password",
				lazy: async () => ({
					Component: (await import("./pages/auth/forgot-password")).default,
				}),
			},
			{
				path: "/auth/callback",
				lazy: async () => ({
					Component: (await import("./pages/auth/auth-callback")).default,
				}),
			},
			{
				path: "/auth/google/callback",
				lazy: async () => ({
					Component: (await import("./pages/auth/auth-callback")).default,
				}),
			},

			// Main routes
			{
				path: "/",
				element: <ProtectedRoute />,
				children: [
					{
						path: "/",
						lazy: async () => {
							const AppShell = await import("./components/app-shell");
							return { Component: AppShell.default };
						},
						errorElement: <GeneralError />,
						children: [
							{
								index: true,
								element: (
									<React.Suspense fallback={<div>Loading...</div>}>
										<ErrorBoundary
											fallback={<div>Error loading Trades component</div>}
										>
											<Dashboard />
										</ErrorBoundary>
									</React.Suspense>
								),
								lazy: async () => ({
									Component: (await import("./pages/dashboard")).default,
								}),
							},
							// {
							//   index: true,
							//   lazy: async () => ({
							//     Component: (await import("./pages/backtest/detail")).default,
							//   }),
							// },
							{
								path: "backtest",
								// Component: Backtest,
								lazy: async () => ({
									Component: (await import("./pages/backtest")).default,
								}),
							},
							{
								path: "backtest/:id",

								lazy: async () => ({
									Component: (await import("./pages/backtest/detail")).default,
								}),
							},
							{
								path: "chats",
								lazy: async () => ({
									Component: (await import("@/components/coming-soon")).default,
								}),
							},
							{
								path: "users",
								lazy: async () => ({
									Component: (await import("@/components/coming-soon")).default,
								}),
							},
							{
								path: "trades",
								element: (
									<React.Suspense fallback={<div>Loading...</div>}>
										<ErrorBoundary
											fallback={<div>Error loading Trades component</div>}
										>
											<Trades />
										</ErrorBoundary>
									</React.Suspense>
								),
								lazy: async () => ({
									Component: (await import("./pages/trades")).default,
								}),
							},
							{
								path: "trades/:id",

								lazy: async () => ({
									Component: (await import("./pages/trades/detail")).default,
								}),
							},
							{
								path: "calender",
								lazy: async () => ({
									Component: (await import("./pages/trade-calender")).default,
								}),
							},
							{
								path: "journal",
								lazy: async () => ({
									Component: (await import("@/pages/journal")).default,
								}),
							},
							{
								path: "chart",
								lazy: async () => ({
									Component: (await import("@/pages/chart")).default,
								}),
							},
							{
								path: "analysis",
								lazy: async () => ({
									Component: (await import("@/pages/analysis")).default,
								}),
							},

							{
								path: "settings",
								// Component: Settings,
								Component: Settings,
								// lazy: async () => ({
								//   Component: (await import("@/components/coming-soon")).default,
								// }),
								children: [
									{
										index: true,
										lazy: async () => ({
											Component: (await import("./pages/settings/profile"))
												.default,
										}),
									},
									{
										path: "profile",
										lazy: async () => ({
											Component: (await import("./pages/settings/profile"))
												.default,
										}),
									},
									{
										path: "account",
										lazy: async () => ({
											Component: (await import("./pages/settings/account"))
												.default,
										}),
									},
									{
										path: "billing",
										lazy: async () => ({
											Component: (await import("@/components/coming-soon"))
												.default,
										}),
									},
									{
										path: "appearance",
										lazy: async () => ({
											Component: (await import("./pages/settings/appearance"))
												.default,
										}),
									},
									{
										path: "notifications",
										lazy: async () => ({
											Component: (
												await import("./pages/settings/notifications")
											).default,
										}),
									},
									{
										path: "display",
										lazy: async () => ({
											Component: (await import("./pages/settings/display"))
												.default,
										}),
									},
									{
										path: "error-example",
										lazy: async () => ({
											Component: (
												await import("./pages/settings/error-example")
											).default,
										}),
										errorElement: (
											<GeneralError className="h-[50svh]" minimal />
										),
									},
								],
							},
						],
					},
				],
			},

			// Error routes
			{ path: "/500", Component: GeneralError },
			{ path: "/404", Component: NotFoundError },
			{ path: "/503", Component: MaintenanceError },

			// Fallback 404 route
			{ path: "*", Component: NotFoundError },
		],
	},
]);

export default router;
