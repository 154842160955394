import { models } from "@/types/interfaces";
import { apiFetch } from "./utils";

let API_BASE_URL: string | undefined;

API_BASE_URL = import.meta.env.VITE_BASEURL;
API_BASE_URL = API_BASE_URL ? `${API_BASE_URL}/api/parse` : undefined;

export async function ParseTrades(file: File, platform: string) {
	const formData = new FormData();
	formData.append("file", file, file.name);
	// const response = await fetch(`${API_BASE_URL}/${platform}`, {
	// 	method: "POST",
	// 	headers: {
	// 		accept: "application/json",
	// 	},
	// 	body: formData,
	// });

	return apiFetch<models.Trade>(`${API_BASE_URL}/${platform}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: formData,
	});
}
