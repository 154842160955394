import { controller } from "@/types/interfaces";
import { apiFetch } from "./utils";

let API_BASE_URL = import.meta.env.VITE_BASEURL;
API_BASE_URL = API_BASE_URL ? `${API_BASE_URL}/api/trades` : undefined;

if (!API_BASE_URL) {
	console.warn("VITE_BASEURL is not defined in environment variables");
}

export async function GetDashboardData(): Promise<controller.DashboardData> {
	if (!API_BASE_URL) {
		throw new Error(
			"API_BASE_URL is not defined. Check your environment variables."
		);
	}
	const response = await apiFetch(`${API_BASE_URL}/dashboard`);

	if (!response || typeof response !== "object") {
		throw new Error("Invalid response from dashboard API");
	}

	if (!("data" in response)) {
		throw new Error("Response missing data property");
	}

	return response.data as controller.DashboardData;
}
