import PieChart from "@/components/charts/pie-chart";
import { Layout, LayoutBody } from "@/components/custom/layout";
import AddTradeButton from "@/components/trade/add-trade";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import useDashboardStore from "@/redux/slice/dashboardSlice";
import {
	ArrowBottomRightIcon,
	ArrowTopRightIcon,
	EyeOpenIcon,
	GearIcon,
} from "@radix-ui/react-icons";
import { IconCurrencyDollar, IconTrophyOff } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import CountUp from "react-countup";

import { Switch } from "@/components/ui/switch";
import { Toggle } from "@/components/ui/toggle";
import { useAuth } from "@/contexts/AuthContext";
import CalenderResponsive from "../trade-calender/components/calender/calender-responsive";
import OverviewBar from "./components/charts/bar-chart";
import OverviewLine from "./components/charts/line-chart";
import { RecentTrades } from "./components/recent-trades";
import SingleBarChart from "./components/singleBar";
import Loader from "@/components/custom/loader";
import GeneralError from "../errors/general-error";
import { useIsMobile } from "@/hooks/use-mobile";
export default function Dashboard() {
	const { dashboardData, loading, error, fetchDashboardData } =
		useDashboardStore();
	const [skipZero, setSkipZero] = useState(false);
	const [percentage, setPercentage] = useState(false);

	const handleAddTrade = () => {
		fetchDashboardData();
	};
	const isMobile = useIsMobile();

	useEffect(() => {
		fetchDashboardData();
	}, []);

	if (loading) {
		return (
			<div className="flex items-center justify-center h-screen">
				<Loader size="large" />
			</div>
		); // Or a more sophisticated loading component
	}

	// if (error) {
	// 	return <GeneralError />; // Or a more user-friendly error component
	// }
	if (!dashboardData) {
		return (
			<div>
				<div>No Data Found Please add trade</div>
				<AddTradeButton successCallback={handleAddTrade} />
			</div>
		);
	}
	console.log("dashboardData", dashboardData);
	return (
		<Layout>
			{/* ===== Main ===== */}
			<LayoutBody className="space-y-2">
				{/* <div className="flex items-center justify-between space-y-2">
					<div className="flex space-x-4">
						<h1 className="text-xl font-bold tracking-tight md:text-2xl">
							Dashboard
						</h1>
					</div>
				</div> */}
				<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 gap-2 w-full">
					<Card className="w-full">
						<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1 pt-2">
							<CardTitle className="text-xs md:text-sm font-bold md:font-medium">
								Account Balance
							</CardTitle>
							<div>
								{dashboardData?.pnl > 0 ? (
									<ArrowTopRightIcon className="h-4 w-4 text-emerald-500" />
								) : (
									<ArrowBottomRightIcon className="h-4 w-4 text-rose-500" />
								)}
							</div>
						</CardHeader>
						<CardContent>
							<div className="text-xl font-bold">
								<CountUp
									end={dashboardData?.account_balance || 0}
									start={dashboardData?.initial_balance || 0}
									duration={1}
									separator=","
									prefix="$"
									decimal="."
									decimals={2}
								/>
							</div>
							<p className="text-xs md:text-sm text-muted-foreground">
								<div className="flex items-center justify-between">
									{dashboardData.pnl > 0 ? (
										<div className="text-emerald-500">
											+$
											{(
												dashboardData?.account_balance -
												dashboardData?.initial_balance
											).toFixed(2)}
										</div>
									) : (
										<div className="text-rose-500 space-x-2">
											<span>
												$
												{(
													dashboardData?.account_balance -
													dashboardData?.initial_balance
												).toFixed(2)}
											</span>
											<span>
												{(
													(dashboardData.pnl / dashboardData.initial_balance) *
													100
												).toFixed(2)}
												%
											</span>
										</div>
									)}
								</div>
							</p>
						</CardContent>
					</Card>
					<Card className="w-full">
						<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1 pt-2">
							<CardTitle className="text-xs md:text-sm font-bold  md:font-medium">
								Net PnL
							</CardTitle>
							<IconCurrencyDollar className="h-4 w-4 text-muted-foreground" />
						</CardHeader>
						<CardContent>
							<div
								className={`${
									dashboardData?.pnl > 0
										? "text-emerald-500 text-xl font-bold"
										: "text-rose-500 text-xl font-bold"
								}`}
							>
								<CountUp
									end={dashboardData?.gross_profit || 0}
									duration={1}
									separator=","
									prefix="$"
									decimal="."
									decimals={2}
								/>
							</div>
							{/* <p className="text-xs md:text-sm text-muted-foreground">
								+20.1% from last month
							</p> */}
						</CardContent>
					</Card>
					<Card className="w-full">
						<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1 pt-2">
							<CardTitle className="text-xs md:text-sm font-bold md:font-medium">
								Win Percentage
							</CardTitle>
							<IconTrophyOff className="h-4 w-4 text-muted-foreground" />
						</CardHeader>
						<CardContent>
							<div className="flex items-center justify-center relative">
								<div className="absolute top-[80%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
									<div className="text-sm md:text-xl font-bold">
										<CountUp
											end={dashboardData?.win_rate || 0}
											duration={1}
											separator=","
											// prefix=""
											suffix="%"
											decimal="."
											decimals={2}
										/>
									</div>
									<div className="text-xs md:text-sm text-muted-foreground">
										Win/Loss
									</div>
								</div>
								<PieChart
									data={dashboardData?.win_loss || []}
									angles={[180, 0]}
									radii={[isMobile ? 45 : 55, isMobile ? 55 : 70]}
								/>
							</div>
						</CardContent>
					</Card>
					<Card className="w-full">
						<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1 pt-2">
							<CardTitle className="text-xs md:text-sm font-bold md:font-medium">
								Profit Factor
							</CardTitle>
							<IconTrophyOff className="h-4 w-4 text-muted-foreground" />
						</CardHeader>
						<CardContent>
							<div className="flex items-center justify-center relative">
								<div className="absolute top-[80%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
									<div className="text-sm md:text-xl font-bold">
										<CountUp
											end={dashboardData?.profit_factor || 0}
											duration={1}
											separator=","
											decimal="."
											decimals={2}
										/>
									</div>
									<div className="text-xs md:text-sm text-muted-foreground">
										Profit/Loss
									</div>
								</div>
								<PieChart
									data={dashboardData?.profit_loss || []}
									angles={[180, 0]}
									radii={[isMobile ? 45 : 55, isMobile ? 55 : 70]}
								/>
							</div>
						</CardContent>
					</Card>
					<Card className="w-full">
						<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1 pt-2">
							<CardTitle className="text-xs md:text-sm font-bold mb-2 md:font-medium">
								Average Win/Loss
							</CardTitle>
						</CardHeader>
						<CardContent className="h-full w-full">
							<SingleBarChart
								avgWin={dashboardData?.average_win || 0}
								avgLoss={dashboardData?.average_loss || 0}
							/>
							<div className="flex items-center justify-between">
								<div className="text-xs md:text-sm text-muted-foreground">
									Avg Win
								</div>
								<div className="text-xs md:text-sm text-muted-foreground">
									Avg Loss
								</div>
							</div>
							<div className="flex items-center justify-between mt-2">
								<div className="text-xs md:text-sm text-emerald-500 font-bold">
									{dashboardData?.average_win?.toFixed(2)}
								</div>
								<div className="text-xs md:text-sm text-rose-500 font-bold">
									{dashboardData?.average_loss?.toFixed(2)}
								</div>
							</div>
						</CardContent>
					</Card>
					<Card className="w-full">
						<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1 pt-2">
							<CardTitle className="text-xs md:text-sm font-bold md:font-medium">
								Avg Win/Loss
							</CardTitle>
						</CardHeader>
						<CardContent></CardContent>
					</Card>
					<Card className="w-full">
						<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1 pt-2">
							<CardTitle className="text-xs md:text-sm font-bold md:font-medium">
								Avg Win/Loss
							</CardTitle>
						</CardHeader>
						<CardContent></CardContent>
					</Card>
					<Card className="w-full">
						<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1 pt-2">
							<CardTitle className="text-xs md:text-sm font-bold md:font-medium">
								Avg Win/Loss
							</CardTitle>
						</CardHeader>
						<CardContent></CardContent>
					</Card>
				</div>
				<div className="grid gap-2 grid-cols-1 sm:grid-cols-2">
					<Card className="w-full">
						<CardHeader className="flex flex-row items-center justify-between ">
							<CardTitle>Profits</CardTitle>
							<Toggle
								size={"sm"}
								aria-label="Toggle italic"
								onClick={() => setSkipZero(!skipZero)}
							>
								{skipZero ? <EyeOpenIcon /> : <EyeOpenIcon />}
							</Toggle>
						</CardHeader>
						<CardContent>
							<OverviewBar
								data={dashboardData?.profit_by_days || []}
								skipZero={skipZero}
							/>
						</CardContent>
					</Card>
					<Card className="w-full">
						<CardHeader className="flex flex-row items-center justify-between ">
							<div className="flex items-center justify-between space-x-2">
								<CardTitle>P&L</CardTitle>
								<Switch onClick={() => setPercentage(!percentage)} />
							</div>
							<IconCurrencyDollar className="h-4 w-4 text-muted-foreground" />
						</CardHeader>
						<CardContent>
							<OverviewLine
								chartData={dashboardData?.equity_growth || []}
								initialBalance={100000}
								percentage={percentage}
							/>
						</CardContent>
					</Card>
				</div>

				<div className="grid gap-2 grid-cols-1 lg:grid-cols-3">
					<Card className="col-span-1 lg:col-span-1 m-0 ">
						<CardHeader className="flex flex-row justify-between items-center">
							<CardTitle>Your Trades</CardTitle>
							<Button variant={"ghost"} size={"icon"}>
								{" "}
								<GearIcon className="h-5 w-5" />{" "}
							</Button>
						</CardHeader>
						<CardContent className="flex flex-col justify-start h-full ">
							<div className="flex flex-col justify-start mt-0">
								{/* <PiChart data={data} angles={[180, 0]} radii={[60, 80]} /> */}
								<ScrollArea className="w-full h-[800px]">
									<RecentTrades trades={dashboardData?.trades || []} />
								</ScrollArea>
							</div>
						</CardContent>
					</Card>

					<Card className="col-span-1 lg:col-span-2">
						<CardHeader>
							<CardTitle>Calender</CardTitle>
						</CardHeader>
						<CardContent className="h-[850px] overflow-auto">
							<CalenderResponsive />
						</CardContent>
					</Card>
				</div>
			</LayoutBody>
		</Layout>
	);
}
