import { supabase } from "@/lib/supabase";

interface FetchOptions extends RequestInit {
	skipAuth?: boolean;
}

export async function apiFetch<T>(
	url: string,
	options: FetchOptions = {}
): Promise<T> {
	const { skipAuth = false, ...fetchOptions } = options;

	try {
		// Add auth header if not skipped
		if (!skipAuth) {
			const {
				data: { session },
				error,
			} = await supabase.auth.getSession();
			if (error || !session) {
				throw new Error("Authentication error");
			}

			fetchOptions.headers = {
				...fetchOptions.headers,
				Authorization: `Bearer ${session.access_token}`,
			};
		}

		// Make the request
		const response = await fetch(url, fetchOptions);

		// Handle 401 (Unauthorized)
		if (response.status === 401 && !skipAuth) {
			// Try to refresh the session
			const {
				data: { session },
				error,
			} = await supabase.auth.refreshSession();
			if (error || !session) {
				throw new Error("Session expired. Please login again.");
			}

			// Retry the request with new token
			fetchOptions.headers = {
				...fetchOptions.headers,
				Authorization: `Bearer ${session.access_token}`,
			};

			const retryResponse = await fetch(url, fetchOptions);
			if (!retryResponse.ok) {
				throw new Error(`HTTP error! status: ${retryResponse.status}`);
			}
			return retryResponse.json();
		}

		// Handle other errors
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		return response.json();
	} catch (error) {
		console.error("API request failed:", error);
		throw error;
	}
}
