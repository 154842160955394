import { models } from "@/types/interfaces";
import { apiFetch } from "./utils";

let API_BASE_URL: string | undefined;
API_BASE_URL = import.meta.env.VITE_BASEURL;
API_BASE_URL = API_BASE_URL ? `${API_BASE_URL}/api/back-tests` : undefined;

export async function GetAllBackTests(): Promise<models.BackTest[]> {
	return apiFetch<models.BackTest[]>(`${API_BASE_URL}`);
}

export async function GetBackTestByUUID(
	uuid: string
): Promise<models.BackTest> {
	return apiFetch<models.BackTest>(`${API_BASE_URL}/${uuid}`);
}

export async function CreateBackTest(
	backTest: models.BackTest
): Promise<models.BackTest> {
	return apiFetch<models.BackTest>(`${API_BASE_URL}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(backTest),
	});
}

export async function UpdateBackTest(
	backTest: models.BackTest
): Promise<models.BackTest> {
	return apiFetch<models.BackTest>(`${API_BASE_URL}/${backTest.uuid}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(backTest),
	});
}

export async function DeleteBackTestByUUID(uuid: string): Promise<void> {
	return apiFetch(`${API_BASE_URL}/${uuid}`, {
		method: "DELETE",
	});
}

export async function AddProfitTrade(
	trade: models.BackTestTrades,
	uuid: string
): Promise<models.BackTest> {
	return apiFetch<models.BackTest>(`${API_BASE_URL}/${uuid}/add-profit`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(trade),
	});
}

export async function AddLossTrade(
	trade: models.BackTestTrades,
	uuid: string
): Promise<models.BackTest> {
	return apiFetch<models.BackTest>(`${API_BASE_URL}/${uuid}/add-loss`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(trade),
	});
}

export async function AddBreakEvenTrade(
	trade: models.BackTestTrades,
	uuid: string
): Promise<void> {
	return apiFetch(`${API_BASE_URL}/${uuid}/add-break-even`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(trade),
	});
}

export async function DeleteMultipleBackTestsByUUID(
	uuids: string[]
): Promise<void> {
	return apiFetch(`${API_BASE_URL}/multiple`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(uuids),
	});
}
