import React from "react";

interface LoaderProps {
	size?: "small" | "medium" | "large";
	color?: string;
}

const Loader: React.FC<LoaderProps> = ({
	size = "medium",
	color = "currentColor",
}) => {
	const sizeClasses = {
		small: "w-4 h-4",
		medium: "w-8 h-8",
		large: "w-12 h-12",
	};

	return (
		<div className="flex items-center justify-center">
			<div
				className={`${sizeClasses[size]} border-2 border-t-transparent rounded-full animate-spin`}
				style={{
					borderTopColor: color,
					borderRightColor: color,
					borderBottomColor: color,
				}}
				role="status"
				aria-label="Loading"
			>
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
};

export default Loader;
